// This is used as a transitional app wrapper for our client-rendered create-react-app portal w/ react-router

import { useRouter } from 'next/router'
import { Router, StaticRouter } from 'react-router-dom'

import { isServer } from '@/util/env'
import history from '@/util/history'

export default function PortalLayout({ children }: any) {
  const router = useRouter()

  // Dynamically pick the right router for server vs client
  let ReactRouter = (props: any) => {
    return <Router history={history} {...props} />
  }
  if (isServer) {
    // On server use a static router, and pass the router path in as the router's location
    // NOTE that `router.asPath` will not have resolved dynamic paths but will be like "/[company_slug]/[...rest]"
    // eslint-disable-next-line react/display-name
    ReactRouter = (props: any) => {
      return <StaticRouter history={history} location={router.asPath} {...props} />
    }
  }

  return <ReactRouter>{children}</ReactRouter>
}
