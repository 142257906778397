import React from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import dynamic from 'next/dynamic'

import { makeTitle } from '@/util/title'
import PortalLayout from '@/layouts/PortalLayout'

// Dynamically import portal and dont try to server-render it
const PortalRoot = dynamic(() => import('@/portal/Root'), { ssr: false })

interface Props {
  title?: string

  server?: {
    // From getSentryServerSideProps
    trace?: string
  }
}

interface FC extends React.FC<Props> {
  Layout?: any
}

const DynamicPortalRoute: FC = ({ title, server }: Props) => {
  const router = useRouter()
  const { company_slug } = router.query

  const pageTitle = makeTitle(title || company_slug)
  return (
    <>
      <Head>
        {server?.trace && <meta name="sentry-trace" content={server.trace} />}
        <title>{pageTitle}</title>
      </Head>
      <PortalRoot title={pageTitle} companySlug={company_slug} />
    </>
  )
}

DynamicPortalRoute.Layout = PortalLayout

export default DynamicPortalRoute
