// FIXME FIXME FIXME
// TODO some kind of redirect to /:company_slug AND/OR company picker screen

// import React from 'react'
// import Head from 'next/head'

// import { makeTitle } from '@/util/title'

// const Home: React.FC = () => {
//   return (
//     <div>
//       <Head>
//         <title key="title">{makeTitle('PORTAL NEXT')}</title>
//       </Head>

//       <main>
//         {"You've landed on the root, what will we do with you?"}
//         <a href="/login">Login</a>
//       </main>
//     </div>
//   )
// }

// export default Home

import DynamicPortalRoute from '@/components/DynamicPortalRoute'
export default DynamicPortalRoute
